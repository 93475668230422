import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import React from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import MaskImg from "../assets/images/mask.png";
import CardIconImg from "../assets/images/cardicon.png";
import PaypalImg from "../assets/images/paypal.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { error } from "console";
// import "./App.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import CheckoutForm from "./payment/CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51QAbUw06cpz94vbkvwupnWEXcEIyfwSnUWJZNL4Yg0vFNXcJl4doxDkiJjJKg82043S4de1CeW3d2CxG7OsxhAd6005CG0vEvj"
  // "pk_test_51QAbUw06cpz94vbkv4rFXu3DweJxSvXEE90vcIosXuCfBcxhqyLqXxH9uTBGSZ7CJnqJkiWbb3LQehoLV4QUKIst003S7BS6M6"
);

const steps = ["Shopping Cart", "Checkout", "Order Complete"];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  // "& .MuiDialogContent-root": {
  //   padding: theme.spacing(2),
  // },
  // "& .MuiDialogActions-root": {
  //   padding: theme.spacing(1),
  // },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#0A3A22",
    borderTopWidth: 7,
    borderRadius: 1,
  },
}));

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
];

interface Inputs {
  firstname: string; // Corrected here
  lastname: string;
  phone: string | number;
  address: string;
  sub_district: string;
  district: string;
  province: string;
  zip_code: string | number;
}

const initialInput: Inputs = {
  firstname: "", // Corrected here
  lastname: "",
  phone: "",
  address: "",
  sub_district: "",
  district: "",
  province: "",
  zip_code: "",
};

export default function CartVerify() {
  const [carts, setCarts] = useState([]);
  const [totals, setTotals] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [address, setAddress] = useState([]);
  const [input, setInput] = useState(initialInput);
  const [errors, setErrors] = useState<Partial<Inputs>>({});

  const [checkPayment, setCheckPayment] = useState(false);

  const [orderId, setOrderId] = useState("");

  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const reloadCarts = () => {
    const localStorageData: any = localStorage.getItem("data") || null;
    const dataWithCheckedLocal =
      JSON.parse(localStorageData)?.dataWithChecked ?? [];
    const totalLocal = JSON.parse(localStorageData)?.totals;

    const sortCartsByProductCreated = dataWithCheckedLocal.sort(function (
      a: any,
      b: any
    ) {
      var d1: any = new Date(a.productCreatedAt);
      var d2: any = new Date(b.productCreatedAt);
      return d1 - d2;
    });
    const sortCartsByPriceCreated = sortCartsByProductCreated.sort(function (
      a: any,
      b: any
    ) {
      var d1: any = new Date(a.priceCreatedAt);
      var d2: any = new Date(b.priceCreatedAt);
      return d1 - d2;
    });

    setCarts(sortCartsByPriceCreated);
    setTotals(totalLocal);
  };

  const fetchAddress = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/members/getAddress`,
      {
        headers: {
          token: cookies.get("token"),
        },
      }
    );
    if (response.data.address.length === 0) {
      Swal.fire({
        icon: "info",
        title: "แจ้งเตือน",
        text: "กรุณาเพิ่มที่อยู่ก่อน",
      });
    }
    setAddress(response.data.address[0]);

    const {
      address,
      district,
      province,
      sub_district,
      zip_code,
      firstname,
      lastname,
      phone,
    } = response.data.address[0];
    setInput({
      address,
      district,
      province,
      sub_district,
      zip_code,
      firstname,
      lastname,
      phone,
    });
  };

  useEffect(() => {
    if (
      cookies.get("token") != null &&
      cookies.get("token") !== "" &&
      cookies.get("token") !== undefined
    ) {
      reloadCarts();
      fetchAddress();
    } else {
      return navigate("/login");
    }
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleErrorInput = () => {
    let tempErrors: Partial<Inputs> = {};

    if (!input.firstname.trim()) {
      tempErrors.firstname = "First name is required";
    }
    if (!input.lastname.trim()) {
      tempErrors.lastname = "Last name is required";
    }
    if (!input.phone.toString().trim()) {
      tempErrors.phone = "Phone number is required";
    } else if (input.phone.toString().length !== 10) {
      tempErrors.phone = "Phone number must be exactly 10 digits";
    }
    if (!input.address.trim()) {
      tempErrors.address = "Address is required";
    }
    if (!input.sub_district.trim()) {
      tempErrors.sub_district = "Sub-district is required";
    }
    if (!input.district.trim()) {
      tempErrors.district = "District is required";
    }
    if (!input.province.trim()) {
      tempErrors.province = "Province is required";
    }
    if (!input.zip_code.toString().trim()) {
      tempErrors.zip_code = "Zip code is required";
    }

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmitAddress = async () => {
    if (handleErrorInput()) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/members/addAddress/`,
        input,
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      );
      // handleClose();
      Swal.fire({
        title: "เพิ่มที่อยู่เรียบร้อย",
        icon: "success",
      });
      fetchAddress();
      return true;
    } else {
      Swal.fire({
        title: "เพิ่มไม่สำเร็จ",
        icon: "error",
      });
      return false;
      // Handle errors (e.g., display error messages to the user)
    }
  };

  const Buy = async () => {
    const subData: any = [];
    const check = await handleSubmitAddress();
    if (check === false) return false;
    // Swal.fire({
    //   title: "กรุณารอสักครู่",
    //   icon: "info",
    // }).then(() => {
    //   setTimeout(() => {
    //     Swal.close();
    //   }, 1600);
    // });

    // if (input.trim() == "" || input == undefined) {
    //   return Swal.fire({
    //     title: "เกิดข้อผิดพลาด",
    //     text: `สั่งซื้อสินค้าไม่สำเร็จ กรุณากรอกที่อยู่สำหรับจัดส่ง`,
    //     icon: "error",
    //   });
    // }

    // if (selectedImage == "" || selectedImage == undefined) {
    //   return Swal.fire({
    //     title: "เกิดข้อผิดพลาด",
    //     text: `สั่งซื้อสินค้าไม่สำเร็จ กรุณาแนปสลิปการโอน`,
    //     icon: "error",
    //   });
    // }

    const formData = new FormData();

    // formData.append("file", selectedImage);

    const uploadResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/upload/slip-payment`,
      formData,
      {
        headers: {
          token: cookies.get("token"),
        },
      }
    );

    console.log({ uploadRes: uploadResponse.data });

    if (uploadResponse.data.message != "OK") {
      return Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: `สั่งซื้อสินค้าไม่สำเร็จ สลิปเงินโอนไม่ถูกต้อง`,
        icon: "error",
      });
    }

    const cartResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/carts/get`,
      {
        headers: {
          token: cookies.get("token"),
        },
      }
    );

    console.log({ cart: cartResponse.data });

    cartResponse.data.carts.map((dataDetails: any) => {
      subData.push({
        product_id: dataDetails.product_id,
        product_price_id: dataDetails.product_price_id,
        quantity: dataDetails.quantity,
        size: dataDetails.size || null,
      });
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/add/`,
        {
          data: JSON.stringify(subData),
          address: `${input.firstname} ${input.lastname} ${input.phone} ${input.address} ${input.district} ${input.province} ${input.zip_code}`,
          totals: totals,
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message === "OK") {
          console.log(response.data);
          setOrderId(response.data.orderId);
          localStorage.removeItem("data");

          // Swal.fire({
          //   title: "สั่งซื้อสินค้าเรียบร้อยแล้ว",
          //   icon: "success",
          // }).then(() => {
          //   navigate("/cart_complete");
          // });

          setCheckPayment(true);
        } else {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: `สั่งซื้อสินค้าไม่สำเร็จ (${response.data.message})`,
            icon: "error",
          });
        }
      });
  };

  return (
    <Box p={5}>
      <Card
        elevation={2}
        sx={{
          padding: 2,
          borderRadius: 10,
        }}
      >
        <CardContent>
          <Grid container columnSpacing={4} justifyContent={"center"}>
            <Grid item md={12}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<QontoConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>

          <br></br>
          {!checkPayment && (
            <div className="p-0 md:p-4 flex gap-8 items-center justify-center">
              {/* <Box>
              <Button variant="contained" onClick={handleClickOpen}>
                จัดส่ง
              </Button>
            </Box> */}
              <div className="w-full md:w-[40%]">
                {/* <Typography
                sx={{
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                {input.address} {input.sub_district} {input.district}{" "}
                {input.province} {input.zip_code}
              </Typography> */}
                <Box paddingY={1} paddingX={1}>
                  <Typography>ชื่อ *</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="firstname"
                    value={input.firstname}
                    onChange={handleChange}
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>นามสกุล *</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="lastname"
                    value={input.lastname}
                    onChange={handleChange}
                    error={!!errors.lastname}
                    helperText={errors.lastname}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>เบอร์โทรศัพท์มือถือ *</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="phone"
                    value={input.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>บ้านเลขที่ ถนน ซอย</Typography>
                  <TextField
                    size="small"
                    multiline={true}
                    fullWidth
                    name="address"
                    value={input.address}
                    onChange={handleChange}
                    error={!!errors.address}
                    helperText={errors.address}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>แขวง/ตำบล</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="sub_district"
                    value={input.sub_district}
                    onChange={handleChange}
                    error={!!errors.sub_district}
                    helperText={errors.sub_district}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>เขต/อำเภอ *</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="district"
                    value={input.district}
                    onChange={handleChange}
                    error={!!errors.district}
                    helperText={errors.district}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>จังหวัด *</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="province"
                    value={input.province}
                    onChange={handleChange}
                    error={!!errors.province}
                    helperText={errors.province}
                  />
                </Box>
                <Box paddingY={1} paddingX={1}>
                  <Typography>รหัสไปรษณีย์ *</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="zip_code"
                    value={input.zip_code}
                    onChange={handleChange}
                    error={!!errors.zip_code}
                    helperText={errors.zip_code}
                  />
                </Box>
              </div>
              {/* <label htmlFor="upload-button">
              <Button
                variant="contained"
                component="span"
                sx={{
                  padding: "10px 20px",
                  backgroundColor: "#1976d2",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
                Choose File
              </Button>
            </label> */}
            </div>
          )}

          {!checkPayment ? (
            <>
              <Box
                mb={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {preview && (
                  <Box
                    component="img"
                    src={preview}
                    alt=""
                    sx={{
                      width: {
                        xs: "100%",
                        md: "30%",
                      },
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      padding: "5px",
                      marginBottom: "15px",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="upload-button"
                />
              </Box>
              <Box textAlign={"right"} p={3}>
                <Typography variant="h5">รวมทั้งสิ้น</Typography>
                <Typography variant="h5" marginBottom={2}>
                  {totals} บาท
                </Typography>
                <Link to={""}>
                  <Button
                    onClick={() => {
                      Buy();
                    }}
                    variant="contained"
                  >
                    สั่งซื้อ
                  </Button>
                </Link>
              </Box>
            </>
          ) : (
            <Box>
              <CheckoutForm orderId={orderId} totals={totals} />{" "}
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Modal */}
      {/* <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          เพิ่มที่อยู่สำหรับจัดส่ง
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box paddingY={1} paddingX={1} width={300}>
            <Typography>ชื่อ *</Typography>
            <TextField
              size="small"
              fullWidth
              name="firstname"
              value={input.firstname}
              onChange={handleChange}
              error={!!errors.firstname}
              helperText={errors.firstname}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>นามสกุล *</Typography>
            <TextField
              size="small"
              fullWidth
              name="lastname"
              value={input.lastname}
              onChange={handleChange}
              error={!!errors.lastname}
              helperText={errors.lastname}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>เบอร์โทรศัพท์มือถือ *</Typography>
            <TextField
              size="small"
              fullWidth
              name="phone"
              value={input.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>เลขที่</Typography>
            <TextField
              size="small"
              fullWidth
              name="address"
              value={input.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>แขวง/ตำบล</Typography>
            <TextField
              size="small"
              fullWidth
              name="sub_district"
              value={input.sub_district}
              onChange={handleChange}
              error={!!errors.sub_district}
              helperText={errors.sub_district}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>เขต/อำเภอ *</Typography>
            <TextField
              size="small"
              fullWidth
              name="district"
              value={input.district}
              onChange={handleChange}
              error={!!errors.district}
              helperText={errors.district}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>จังหวัด *</Typography>
            <TextField
              size="small"
              fullWidth
              name="province"
              value={input.province}
              onChange={handleChange}
              error={!!errors.province}
              helperText={errors.province}
            />
          </Box>
          <Box paddingY={1} paddingX={1}>
            <Typography>รหัสไปรษณีย์ *</Typography>
            <TextField
              size="small"
              fullWidth
              name="zip_code"
              value={input.zip_code}
              onChange={handleChange}
              error={!!errors.zip_code}
              helperText={errors.zip_code}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmitAddress} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog> */}
    </Box>
  );
}
