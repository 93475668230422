import {
  AccountCircle,
  CloudUploadOutlined,
  DeleteOutline,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "universal-cookie";
import YouTube from "react-youtube";

const Banner = () => {
  const [fileImg, setFileImg] = useState<any>(undefined);
  const [fileImg2, setFile2Img] = useState<any>(undefined);
  const [fileImg3, setFile3Img] = useState<any>(undefined);
  const [fileImg4, setFile4Img] = useState<any>(undefined);
  const [fileImg5, setFile5Img] = useState<any>(undefined);

  const [PfileImg, setPFileImg] = useState<any>(undefined);
  const [PfileImg2, setPFile2Img] = useState<any>(undefined);
  const [PfileImg3, setPFile3Img] = useState<any>(undefined);
  const [PfileImg4, setPFile4Img] = useState<any>(undefined);
  const [PfileImg5, setPFile5Img] = useState<any>(undefined);

  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const [link, setLink] = useState("");

  const handleUpload = (event: any) => {
    setFileImg(event.target.files[0]);
    setPFileImg(URL.createObjectURL(event.target.files[0]));
  };
  const handleUpload2 = (event: any) => {
    setFile2Img(event.target.files[0]);
    setPFile2Img(URL.createObjectURL(event.target.files[0]));
  };
  const handleUpload3 = (event: any) => {
    setFile3Img(event.target.files[0]);
    setPFile3Img(URL.createObjectURL(event.target.files[0]));
  };
  const handleUpload4 = (event: any) => {
    setFile4Img(event.target.files[0]);
    setPFile4Img(URL.createObjectURL(event.target.files[0]));
  };
  const handleUpload5 = (event: any) => {
    setFile5Img(event.target.files[0]);
    setPFile5Img(URL.createObjectURL(event.target.files[0]));
  };

  const uploadBanner = async () => {
    if (fileImg !== undefined) {
      const formData = new FormData();
      formData.append("file", fileImg);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          // console.log(response);
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
            await axios.post(
              `${process.env.REACT_APP_API_URL}/banner/insertpathimg`,
              { path_img: response.data.path },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูปภาพ",
        icon: "error",
      });
    }
  };
  const uploadBanner2 = async () => {
    if (fileImg2 !== undefined) {
      const formData = new FormData();
      formData.append("file", fileImg2);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          // console.log(response);
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
            await axios.post(
              `${process.env.REACT_APP_API_URL}/banner/insertpathimg`,
              { banner: 2, path_img: response.data.path },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูปภาพ",
        icon: "error",
      });
    }
  };
  const uploadBanner3 = async () => {
    if (fileImg3 !== undefined) {
      const formData = new FormData();
      formData.append("file", fileImg3);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          // console.log(response);
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
            await axios.post(
              `${process.env.REACT_APP_API_URL}/banner/insertpathimg`,
              { banner: 3, path_img: response.data.path },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูปภาพ",
        icon: "error",
      });
    }
  };
  const uploadBanner4 = async () => {
    if (fileImg4 !== undefined) {
      const formData = new FormData();
      formData.append("file", fileImg4);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          // console.log(response);
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
            await axios.post(
              `${process.env.REACT_APP_API_URL}/banner/insertpathimg`,
              { banner: 4, path_img: response.data.path },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูปภาพ",
        icon: "error",
      });
    }
  };
  const uploadBanner5 = async () => {
    if (fileImg5 !== undefined) {
      const formData = new FormData();
      formData.append("file", fileImg5);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          // console.log(response);
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
            await axios.post(
              `${process.env.REACT_APP_API_URL}/banner/insertpathimg`,
              { banner: 5, path_img: response.data.path },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูปภาพ",
        icon: "error",
      });
    }
  };

  const linkChange = async () => {
    if (link !== null && link !== undefined && link !== "") {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/banner/insertLink`,
          { link: link },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (
      cookies.get("token") == null ||
      cookies.get("token") === "" ||
      cookies.get("token") === undefined
    ) {
      return navigate("/");
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/token/check`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        if (response.data.isAdmin !== 1) {
          return navigate("/");
        }
      });
    getDataBannerYT();
  }, []);

  const getDataBannerYT = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/banner/getBannerAndYT`)
      .then((response) => {
        setPFileImg(response.data.banner);
        setPFile2Img(response.data.banner2);
        setPFile3Img(response.data.banner3);
        setPFile4Img(response.data.banner4);
        setPFile5Img(response.data.banner5);
        setLink(response.data.youtube);
      });
  };
  interface VideoPlayerProps {
    url: string;
  }

  const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
    const getVideoId = (url: string) => {
      const urlObj = new URL(url);
      return urlObj.searchParams.get("v");
    };

    const videoId = getVideoId(url);

    const opts = {
      height: "390",
      width: "640",
    };

    return videoId ? (
      <YouTube videoId={videoId} opts={opts} />
    ) : (
      <div>Invalid URL</div>
    );
  };

  const [url, setUrl] = useState("");

  return (
    <Grid container spacing={2}>
      <Grid item md={5}>
        <Stack direction="row" alignItems="center">
          <input
            accept="image/*"
            id="upload-photo"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
          <label htmlFor="upload-photo">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Photo
            </Button>
          </label>

          <Typography variant="body2" color="textSecondary">
            &nbsp;&nbsp;Maximum file size: 5MB
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={4}>
        <img
          src={
            PfileImg?.replace("{api_path}", process.env.REACT_APP_API_URL) ||
            "/404-not-found.png"
          }
          alt=""
          className="w-full"
        />
      </Grid>
      <Grid item md={3}>
        <Button
          onClick={() => {
            uploadBanner();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          + เพิ่ม Banner 1
        </Button>
      </Grid>
      <Grid item md={5}>
        <Stack direction="row" alignItems="center">
          <input
            accept="image/*"
            id="upload-photo2"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload2}
          />
          <label htmlFor="upload-photo2">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Photo
            </Button>
          </label>

          <Typography variant="body2" color="textSecondary">
            &nbsp;&nbsp;Maximum file size: 5MB
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={4}>
        <img
          src={
            PfileImg2?.replace("{api_path}", process.env.REACT_APP_API_URL) ||
            "/404-not-found.png"
          }
          alt=""
          className="w-full"
        />
      </Grid>
      <Grid item md={3}>
        <Button
          onClick={() => {
            uploadBanner2();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          + เพิ่ม Banner 2
        </Button>
      </Grid>
      <Grid item md={5}>
        <Stack direction="row" alignItems="center">
          <input
            accept="image/*"
            id="upload-photo3"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload3}
          />
          <label htmlFor="upload-photo3">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Photo
            </Button>
          </label>

          <Typography variant="body2" color="textSecondary">
            &nbsp;&nbsp;Maximum file size: 5MB
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={4}>
        <img
          src={
            PfileImg3?.replace("{api_path}", process.env.REACT_APP_API_URL) ||
            "/404-not-found.png"
          }
          alt=""
          className="w-full"
        />
      </Grid>
      <Grid item md={3}>
        <Button
          onClick={() => {
            uploadBanner3();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          + เพิ่ม Banner 3
        </Button>
      </Grid>
      <Grid item md={5}>
        <Stack direction="row" alignItems="center">
          <input
            accept="image/*"
            id="upload-photo4"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload4}
          />
          <label htmlFor="upload-photo4">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Photo
            </Button>
          </label>

          <Typography variant="body2" color="textSecondary">
            &nbsp;&nbsp;Maximum file size: 5MB
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={4}>
        <img
          src={
            PfileImg4?.replace("{api_path}", process.env.REACT_APP_API_URL) ||
            "/404-not-found.png"
          }
          alt=""
          className="w-full"
        />
      </Grid>
      <Grid item md={3}>
        <Button
          onClick={() => {
            uploadBanner4();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          + เพิ่ม Banner 4
        </Button>
      </Grid>
      <Grid item md={5}>
        <Stack direction="row" alignItems="center">
          <input
            accept="image/*"
            id="upload-photo5"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload5}
          />
          <label htmlFor="upload-photo5">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Photo
            </Button>
          </label>

          <Typography variant="body2" color="textSecondary">
            &nbsp;&nbsp;Maximum file size: 5MB
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={4}>
        <img
          src={
            PfileImg5?.replace("{api_path}", process.env.REACT_APP_API_URL) ||
            "/404-not-found.png"
          }
          alt=""
          className="w-full"
        />
      </Grid>
      <Grid item md={3}>
        <Button
          onClick={() => {
            uploadBanner5();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          + เพิ่ม Banner 5
        </Button>
      </Grid>
      {/* <Grid item sm={12} md={12}>
        <VideoPlayer url={url} />
      </Grid> */}
      <Grid item sm={6} md={6}>
        <FormControl fullWidth>
          <TextField
            id="standard-bare"
            variant="outlined"
            placeholder="Youtube link..."
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            InputProps={{
              sx: {
                height: "45px",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                py: "8px",
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <Button
          onClick={() => {
            linkChange();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          เปลี่ยนวิดีโอ
        </Button>
      </Grid>
    </Grid>
  );
};
export default Banner;
